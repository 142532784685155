import { WidgetProps } from "../../Widget";
import { action } from "mobx";

const { observable } = require("mobx");

class ImageWidgetProps extends WidgetProps{
    /**
     * @var {string}
     */
    @observable imageFile;

    /**
     * @retrun {object}
     */
    toJson() {
        return {
            image_file: this.imageFile
        };
    }

    /**
     * @param {object} json
     * @retrun {this}
     */
    @action fromJson(props) {
        this.imageFile = props.image_file;

        return this;
    }
}

export default ImageWidgetProps;