import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { Widget } from '../../Widget';
import widget from '../../widget';
import IframeWidgetProps from '../models/IframeWidgetProps';
import styles from './IframeWidget.module.less';
import './views';

@observer
@widget({name: 'iframe', props: IframeWidgetProps})
class IframeWidget extends Widget {
  static propTypes = {
    ...Widget.PropTypes,
    url: PropTypes.string.isRequired
  };

  render() {
    return <div className={styles.root}>
        {this.getView({
          url: this.props.url,
          onLoad: this.contentLoaded.bind(this),
          onError: this.contentErrored.bind(this),
          className: styles.root,
        })}
    </div>;
  }

  componentDidMount() {
    this.props.zone.loading = true;
  }

  contentLoaded() {
    this.props.zone.loading = false;
  }

  contentErrored() {
    this.props.zone.loading = false;
  }
}

export default IframeWidget;