import React from "react";
import PropTypes from "prop-types";
import WidgetViewProvider from "../../WidgetViewProvider";
import { Fade } from "react-awesome-reveal";

class Widget extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    zone: PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      loading: PropTypes.bool.isRequired,
      layout: PropTypes.shape({}).isRequired,
    }).isRequired,
    visible: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    visible: true,
  };

  getView(props) {
    const view = WidgetViewProvider.getWidgetViewForZone(
      this.props.name,
      this.props.zone
    );
    return (
      <Fade
        reverse={!this.props.visible}
        style={{ height: "100%", width: "100%" }}
      >
        {view !== null && React.createElement(view, props || this.props)}
      </Fade>
    );
  }
}

export default Widget;
