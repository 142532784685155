import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import { Widget } from "../../Widget";
import widget from "../../widget";
import YoutubeWidgetProps from "../models/YoutubeWidgetProps";
import styles from "./YoutubeWidget.module.less";
import "./views";

@observer
@widget({ name: "youtube", props: YoutubeWidgetProps })
class YoutubeWidget extends Widget {
  static propTypes = {
    ...Widget.PropTypes,
    videoId: PropTypes.string.isRequired,
  };

  render() {
    return (
      <div className={styles.root}>
        {this.getView({
          videoId: this.props.videoId,
          className: styles.root,
        })}
      </div>
    );
  }
}

export default YoutubeWidget;
