import { WidgetProps } from "../../Widget";
import { DateTime } from 'luxon';
import { observable, action } from "mobx";

class ClockWidgetProps extends WidgetProps{
    /**
     * @var {string}
     */
    @observable timezone;

    /**
     * @var {string}
     */
    @observable time;

    /**
     * @var {string}
     */
    @observable nameDaytext;

    /**
     * @var {string}
     */
    @observable bankHolidayText;

    @action setTime() {
        this.time = DateTime.local().setLocale('hu').setZone(this.timezone);
        setTimeout(() => {
            this.setTime();
        }, 1000);
    }

    /**
     * @retrun {object}
     */
    toJson() {
        return {
            timezone: this.timezone
        };
    }

    /**
     * @param {object} json
     * @retrun {this}
     */
    @action fromJson(props) {
        this.timezone = props.timezone;
        this.nameDaytext = 'Kacsa';//;props.name_day_text;
        this.bankHolidayText = 'mária';//props.bank_holiday_text;
        this.setTime();

        return this;
    }
}

export default ClockWidgetProps;