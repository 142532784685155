import { WidgetProps } from "../../Widget";
import { action } from "mobx";

const { observable } = require("mobx");

class VideoWidgetProps extends WidgetProps{
    /**
     * @var {string}
     */
    @observable videoFile;

    /**
     * @var {bool}
     */
    @observable muted = true;


    /**
     * @retrun {object}
     */
    toJson() {
        return {
            video_file: this.videoFile
        };
    }

    /**
     * @param {object} json
     * @retrun {this}
     */
    @action fromJson(props) {
        this.videoFile = props.video_file;

        return this;
    }
}

export default VideoWidgetProps;