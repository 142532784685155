import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { Widget } from '../../Widget';
import widget from '../../widget';
import VideoWidgetProps from '../models/VideoWidgetProps';
import styles from './VideoWidget.module.less';
import './views';

@observer
@widget({name: 'video', props: VideoWidgetProps})
class VideoWidget extends Widget {
  static propTypes = {
    ...Widget.PropTypes,
    videoFile: PropTypes.string.isRequired
  };

  render() {
    return <div className={styles.root}>
        {this.getView({
          url: this.props.videoFile,
          className: styles.root,
        })}
    </div>;
  }
}

export default VideoWidget;