import { WidgetProps } from "../../Widget";
import { action } from "mobx";

const { observable } = require("mobx");

class YoutubeWidgetProps extends WidgetProps {
  /**
   * @var {string}
   */
  @observable videoId;

  /**
   * @retrun {object}
   */
  toJson() {
    return {
      video_id: this.videoId,
    };
  }

  /**
   * @param {object} json
   * @retrun {this}
   */
  @action fromJson(props) {
    this.videoId = props.video_id;

    return this;
  }
}

export default YoutubeWidgetProps;
